import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { auth } from "@/services";
import { api } from "@/services/http";
import router from "@/router";

export const useAuthStore = defineStore("authStore", () => {
  const token = ref(auth.getAuthToken() || "");
  const isLoading = ref(false);

  const isAuth = computed(() => !!token.value);

  const setAuthToken = (authToken) => {
    token.value = authToken;
    auth.setAuthToken(authToken);

    if (!authToken) {
      auth.removeAuthToken();
      api.setAuthToken();
    }
  };

  const login = async (formData) => {
    isLoading.value = true;
    try {
      const { data } = await api.post("/login", formData);

      api.setAuthToken(data.token);
      setAuthToken(data.token);
    } catch (e) {
      throw Error(e);
    } finally {
      isLoading.value = false;
    }
  };

  const logout = async (isAuthError = false) => {
    isLoading.value = true;
    try {
      if (!isAuthError) await api.post("/logout");

      setAuthToken();
      isLoading.value = false;

      router.push({ name: "Login" });
    } catch (e) {
      throw Error(e);
    }
  };

  return {
    isAuth,
    isLoading,
    login,
    logout,
  };
});
