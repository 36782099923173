<script setup>
import { computed, defineProps } from "vue";
import sprite from "@/assets/icons/_sprite.svg";

const props = defineProps({
  name: { type: String, required: true },
  width: { type: String, required: true },
  height: { type: String, required: true },
});

const iconPath = computed(() => `${sprite}#${props.name}`);
</script>

<template>
  <svg
    :width="props.width"
    :height="props.height"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMaxYMax meet"
  >
    <use :xlink:href="iconPath" />
  </svg>
</template>
