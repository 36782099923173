import { createRouter, createWebHistory } from "vue-router";
import { Guard } from "@/router/hooks";

import Layout from "@/layout";

const useComponents = (path) => () => import(`@/views/${path}`);

const routes = [
  { path: "/:pathMatch(.*)*", redirect: "/dashboard" },
  {
    path: "/login",
    name: "Login",
    component: useComponents("Login"),
    meta: {
      title: "Login",
    },
  },
  {
    path: "/",
    redirect: "/dashboard",
    component: Layout,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: useComponents("Dashboard/index"),
        beforeEnter: Guard,
        meta: {
          title: "Dashboard",
        },
      },
    ],
  },
  {
    path: "/notifications",
    component: Layout,
    children: [
      {
        path: "",
        name: "Notifications",
        component: useComponents("Notifications/index"),
        beforeEnter: Guard,
        meta: {
          title: "Notifications",
        },
      },
    ],
  },
  {
    path: "/applications",
    component: Layout,
    children: [
      {
        path: "",
        name: "Applications",
        component: useComponents("Applications/index"),
        beforeEnter: Guard,
        meta: {
          title: "Applications",
        },
      },
    ],
  },
  {
    path: "/settings",
    redirect: "/settings/admin-users",
    component: Layout,
    beforeEnter: Guard,
    children: [
      {
        path: "admin-users",
        name: "AdminUsers",
        component: useComponents("Settings/AdminUsers/index"),
        meta: {
          title: "Admin users",
        },
      },
      {
        path: "roles",
        name: "Roles",
        component: useComponents("Settings/Roles/index"),
        meta: {
          title: "Roles",
        },
      },
      {
        path: "route-permissions",
        name: "RoutePermissions",
        component: useComponents("Settings/RoutePermissions/index"),
        meta: {
          title: "Route permissions",
        },
      },
      {
        path: "tracking-events",
        name: "TrackingEvents",
        component: useComponents("Settings/TrackingEvents/index"),
        meta: {
          title: "Tracking events",
        },
      },
      {
        path: "proofs",
        name: "Proofs",
        component: useComponents("Settings/Proofs/index"),
        meta: {
          title: "Proofs",
        },
      },
    ],
  },
  {
    path: "/reports",
    component: Layout,
    beforeEnter: Guard,
    children: [
      {
        path: "report-of-sent-pushes",
        name: "ReportOfSentPushes",
        component: useComponents("Reports/ReportOfSentPushes/index"),
        meta: {
          title: "Report of sent pushes",
        },
      },
      {
        path: "conversion-report",
        name: "ConversionReport",
        component: useComponents("Reports/ConversionReport/index"),
        meta: {
          title: "Conversion report",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((from, to, next) => {
  document.title = `${from.meta.title} | PUSH`;
  next();
});

export default router;
