const tokenKey = "admin-token";

export const auth = {
  getAuthToken: () => {
    return localStorage.getItem(tokenKey);
  },
  setAuthToken: (token) => {
    return localStorage.setItem(tokenKey, token);
  },
  removeAuthToken: () => {
    return localStorage.removeItem(tokenKey);
  },
};
