import axios from "axios";
import { API_HOST } from "@/config";
import { ElNotification } from "element-plus";
import { useAuthStore } from "@/stores";

const handlersFactory = () => [
  (response) => {
    if (response.headers["x-meta-count"]) {
      return {
        data: response.data.data,
        count: Number(response.headers["x-meta-count"]),
      };
    }

    if (response.data.code !== 0) {
      ElNotification.error({ title: "Error", message: response.data.message });

      return Promise.reject(response.data.message);
    }

    return response.data;
  },
  (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.config &&
      !error.response.config.__isRetryRequest
    ) {
      useAuthStore().logout(true);
    }
    return Promise.reject(
      error.response.data.data?.message || error.response.data.message
    );
  },
];

const api = axios.create({
  baseURL: API_HOST,
});

api.interceptors.response.use(...handlersFactory());

api.setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common["X-Auth-Token"] = token;
  } else {
    delete api.defaults.headers.common["X-Auth-Token"];
  }
};

export { api };
