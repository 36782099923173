import { defineStore } from "pinia";
import { api } from "@/services/http";
import { ElNotification } from "element-plus";

const URL = "/adminUser";

export const useAdminUserStore = defineStore("adminUserStore", () => {
  const getAdminUsers = async (params) => {
    try {
      const { data, count } = await api.get(URL, {
        params,
      });

      return { data, count };
    } catch (e) {
      throw Error(e);
    }
  };

  const createAdminUser = async (formData) => {
    try {
      await api.post(URL, formData);

      ElNotification.success({
        message: `Account for ${formData.email} added`,
      });
    } catch (e) {
      throw Error(e);
    }
  };

  const updateAdminUser = async (formData) => {
    try {
      await api.put(`${URL}/${formData.backOfficeAdminId}`, formData);

      ElNotification.info({
        message: `${formData.email} account updated`,
      });
    } catch (e) {
      throw Error(e);
    }
  };

  const deleteAdminUser = async (adminIdList) => {
    try {
      await api.delete(URL, { params: { adminIdList } });

      ElNotification.error({
        message: "Account deleted",
      });
    } catch (e) {
      throw Error(e);
    }
  };

  return {
    getAdminUsers,
    createAdminUser,
    updateAdminUser,
    deleteAdminUser,
  };
});
