import { defineStore } from "pinia";
import { ref } from "vue";
import { api } from "@/services/http";

export const useDictionaryStore = defineStore("dictionaryForm", () => {
  const dictionaryBrand = ref([]);
  const dictionaryRoute = ref([]);
  const dictionaryCountry = ref([]);
  const dictionaryOS = ref([]);
  const dictionaryOffer = ref([]);

  const getDictionaryBrand = async () => {
    try {
      const { data } = await api.get("/dictionary/brand");

      dictionaryBrand.value = data;
    } catch (e) {
      throw Error(e);
    }
  };
  const getDictionaryRoute = async () => {
    try {
      const { data } = await api.get("/dictionary/route");

      dictionaryRoute.value = data;
    } catch (e) {
      throw Error(e);
    }
  };
  const getDictionaryCountry = async () => {
    try {
      const { data } = await api.get("/dictionary/country");

      dictionaryCountry.value = data;
    } catch (e) {
      throw Error(e);
    }
  };
  const getDictionaryOS = async () => {
    try {
      const { data } = await api.get("/dictionary/os");

      dictionaryOS.value = data;
    } catch (e) {
      throw Error(e);
    }
  };
  const getDictionaryOffer = async () => {
    try {
      const { data } = await api.get("/dictionary/offer");

      dictionaryOffer.value = data;
    } catch (e) {
      throw Error(e);
    }
  };

  return {
    dictionaryBrand,
    dictionaryRoute,
    dictionaryCountry,
    dictionaryOS,
    dictionaryOffer,
    getDictionaryBrand,
    getDictionaryRoute,
    getDictionaryCountry,
    getDictionaryOS,
    getDictionaryOffer,
  };
});
