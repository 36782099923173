<script setup>
import Navigation from "@/layout/components/Navigation";
import Loader from "@/layout/components/Loader";

import { ref } from "vue";
import { useDictionaryStore, useProfileStore } from "@/stores";

const dictionaryStore = useDictionaryStore();
const profileStore = useProfileStore();

const isLoading = ref(true);

const fetchRequiredData = () => {
  Promise.allSettled([
    profileStore.getProfile(),
    dictionaryStore.getDictionaryBrand(),
    dictionaryStore.getDictionaryRoute(),
    dictionaryStore.getDictionaryCountry(),
    dictionaryStore.getDictionaryOS(),
    dictionaryStore.getDictionaryOffer(),
  ]).finally(() => {
    isLoading.value = false;
  });
};

fetchRequiredData();
</script>

<template>
  <Loader v-if="isLoading" />
  <el-container v-else>
    <el-header>
      <Navigation />
    </el-header>
    <el-main>
      <router-view />
    </el-main>
  </el-container>
</template>
