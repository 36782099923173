import { defineStore } from "pinia";
import { api } from "@/services/http";
import { computed, ref } from "vue";

const URL = "/role";

export const useRoleStore = defineStore("roleStore", () => {
  const roles = ref([]);

  const getRoles = async ({ limit = 1000, offset = 0, ...filters }) => {
    try {
      const { data, count } = await api.get(URL, {
        params: { limit, offset, ...filters },
      });

      roles.value = data;

      return { data, count };
    } catch (e) {
      throw Error(e);
    }
  };

  const getRolesSlug = computed(() => {
    return roles.value.map(({ slug }) => slug);
  });

  return {
    getRoles,
    getRolesSlug,
  };
});
