import { defineStore } from "pinia";
import { ref } from "vue";
import { api } from "@/services/http";

export const useProfileStore = defineStore("profileStore", () => {
  const profile = ref({});

  const getProfile = async () => {
    try {
      const { data } = await api.get("/profile");

      profile.value = data;
    } catch (e) {
      throw Error(e);
    }
  };

  return {
    profile,
    getProfile,
  };
});
