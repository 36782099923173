import { createApp } from "vue";
import { createPinia } from "pinia";

import ElementPlus from "element-plus";
import "@/assets/styles/elementPlus.scss";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "@/assets/styles/index.scss";

import { api } from "@/services/http";
import { auth } from "@/services";

import App from "./App.vue";
import router from "./router";

import moment from "moment-timezone";

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(ElementPlus, { size: "default" });

//Registration all icons
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

const token = auth.getAuthToken();

if (token) {
  api.setAuthToken(token);
}

app.config.globalProperties.$filters = {
  formatDate(value) {
    if (value) {
      return moment.unix(value).format("YYYY-MM-DD HH:mm:ss");
    }
  },
};

//Global prop api
app.config.globalProperties.$api = api;

app.mount("#app");

//TEST RELEASE
